
import { Component } from 'vue-property-decorator';
import moment from 'moment';
import PwrVue from '@/components/PwrVue';
import pwrLogoGrayEn from '@/assets/pwr/logo PWr biale ang poziom  bez tla.png';
import pwrLogoGrayPl from '@/assets/pwr/logo PWr biale poziom  bez tla.png';
import godloPolski from '@/assets/polska/godlo_polski.png';
import PwrFooterItem from '@/components/Pwr/PwrFooter/PwrFooterItem.vue';

@Component({
  components: { PwrFooterItem }
})
export default class PwrFooter extends PwrVue {
  get pwrLogoGrayscale() {
    return this.language !== 'pl' ? pwrLogoGrayEn : pwrLogoGrayPl;
  }

  get godlo() {
    return godloPolski;
  }

  get copyrightYear(): string {
    return moment().format('Y');
  }
}
