
import { Component, Prop } from 'vue-property-decorator';
import { AuthService, PermissionsHelper } from '@/modules/core/core-auth';
import { RoutesNames as AdminRoutesNames } from '@/router/admin/types';
import { UserBaseModel } from '@/modules/core/core-types';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';

@Component({
  components: { PwrCard, PwrBtn }
})
export default class OpAvatarContent extends PwrVue {
  @Prop() borderless!: string | boolean;

  private showSuperAdminButton = false;

  get name(): string {
    if (this.user && this.user.name) {
      return this.user.name;
    }

    return '';
  }

  get email(): string {
    if (this.user && this.user.email) {
      return this.user.email;
    }

    return '';
  }

  get roleName(): string {
    if (this.user && this.user.roles && this.user.roles.length) {
      return this.user.roles[0].name;
    }

    return '';
  }

  private async onSuperAdminButtonClick(): Promise<void> {
    await this.$router.push({ name: AdminRoutesNames.SUPER_ADMIN_MENU });
  }

  private async mounted(): Promise<void> {
    AuthService.onLogin(this.checkPermissions);

    let { user } = this;

    if (this.user) {
      this.checkPermissions(user);
    } else {
      user = await AuthService.me();

      if (this.user) {
        this.checkPermissions(user);
      }
    }
  }

  private checkPermissions(user: UserBaseModel | null): void {
    if (user) {
      PermissionsHelper.hasRole('super_administrator', user).then((result: boolean) => {
        this.showSuperAdminButton = result;
      });
    }
  }
}
