
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class PwrMenuBtn extends PwrVue {
  @Prop() routeNameReference?: string;

  private onClick(): void {
    this.resetAfkTimer();
    this.$emit('click');
  }
}
