
import { Component } from 'vue-property-decorator';
import { AuthService } from '@/modules/core/core-auth';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import OpAvatarContent from '@/components/Op/Layout/OpAvatar/OpAvatarContent.vue';

@Component({
  components: { OpAvatarContent, PwrCard, PwrBtn }
})
export default class OpAvatar extends PwrVue {
  private loading = false;

  get initials(): string {
    if (this.user) {
      return `${this.user?.name ? this.user.name[0] : ' '}${
        this.user?.surname ? this.user.surname[0] : ' '
      }`;
    }

    return '  ';
  }

  private async created(): Promise<void> {
    if (this.isUserLoggedIn() && !this.user) {
      this.loading = true;
      await AuthService.me();
      this.loading = false;
    }
  }
}
