
import { Component } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import {
  GetterTypes as SnackbarGetterTypes,
  MutationTypes as SnackbarMutationTypes
} from '@/store/snackbar/types';
import { GetterTypes } from '@/store/types';
import PwrVue from '@/components/PwrVue';
import LangSelectorService from '@/modules/LangSelector/LangSelectorService';
import { RoutesNames as SiteRoutesNames } from '@/router/site/types';
import { RoutesNames as AdminRoutesNames } from '@/router/admin/types';
import RouterLinkData from '@/components/Op/Layout/types';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import PwrNavBar from '@/components/Pwr/PwrNavBar/PwrNavBar.vue';
import PwrNavItem from '@/components/Pwr/PwrNavBar/PwrNavItem.vue';
import PwrFooter from '@/components/Pwr/PwrFooter/PwrFooter.vue';
import PwrFooterItem from '@/components/Pwr/PwrFooter/PwrFooterItem.vue';
import OpFooter from '@/components/Op/Layout/OpFooter.vue';
import OpNavigation from '@/components/Op/Layout/OpNavigation.vue';
import PwrYesNoDialog from '@/components/Pwr/Dialogs/PwrYesNoDialog.vue';
import PwrSnackbar from '@/components/Pwr/PwrSnackbar/PwrSnackbar.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrLangSelector from '@/components/Pwr/PwrLangSelector/PwrLangSelector.vue';

const snackbarNamespace = 'snackbar';

@Component({
  components: {
    PwrBtn,
    PwrSnackbar,
    PwrYesNoDialog,
    OpNavigation,
    OpFooter,
    PwrFooterItem,
    PwrFooter,
    PwrNavItem,
    PwrNavBar,
    PwrLangSelector
  }
})
export default class OpLayout extends PwrVue {
  @Getter(GetterTypes.GET_ENABLE_SCROLL_UP_FAB) isScrollUpFabEnabled!: boolean;

  @Getter(SnackbarGetterTypes.GET_MESSAGE, { namespace: snackbarNamespace })
  snackbarMessage!: string;

  @Getter(SnackbarGetterTypes.GET_TYPE, { namespace: snackbarNamespace })
  snackbarType!: PwrSnackbarTypes;

  @Getter(SnackbarGetterTypes.GET_IS_OPEN, { namespace: snackbarNamespace })
  snackbarGetIsOpen!: boolean;

  @Mutation(SnackbarMutationTypes.SET_IS_OPEN, { namespace: snackbarNamespace })
  snackbarSetIsOpen!: (value: boolean) => void;

  @Getter(SnackbarGetterTypes.GET_TIMEOUT, { namespace: snackbarNamespace })
  snackbarTimeout!: number;

  get snackbarVisible() {
    return this.snackbarGetIsOpen;
  }

  set snackbarVisible(val: boolean) {
    this.snackbarSetIsOpen(val);
  }

  private defaultNavBarItems: RouterLinkData[] = [
    {
      name: SiteRoutesNames.OFFERS_FOR_TEACHERS,
      displayedName: this.$t('layouts.pwr.navbar.actions.jobOffersForTeacher') as string
    },
    {
      name: SiteRoutesNames.COMPETITIONS_RESULTS,
      displayedName: this.$t('layouts.pwr.navbar.actions.competitionsResults') as string
    },
    {
      name: SiteRoutesNames.OFFERS_FOR_NOT_TEACHERS_OFFERS,
      displayedName: this.$t('layouts.pwr.navbar.actions.jobOffersForAdministration') as string
    },
    {
      name: SiteRoutesNames.CONTACT,
      displayedName: this.$t('layouts.pwr.navbar.actions.contact') as string
    },
    {
      name: AdminRoutesNames.OFFERS_ADMINISTRATION,
      displayedName: this.$t('layouts.pwr.navbar.actions.administration') as string,
      authorized: true
    },
    {
      // TODO dodać do tego zadanko na podstawie feedbacku od zleceniodawcy
      // TODO dodać tłumaczenia i routing
      name: SiteRoutesNames.CAREER,
      displayedName: SiteRoutesNames.CAREER,
      visible: false
    }
  ];

  /**
   * Snackbar
   */

  get snackbar(): boolean {
    return this.snackbarGetIsOpen;
  }

  set snackbar(value: boolean) {
    this.snackbarSetIsOpen(value);
  }

  /**
   * Cookies info dialog
   */
  private cookiesInfoAck = 'COOKIES_INFO_ACK';
  private showCookiesInfoDialog: boolean = false;

  private onCookiesInfoAgree() {
    localStorage.setItem(this.cookiesInfoAck, 'true');
    this.showCookiesInfoDialog = false;
  }

  private scrollTopButton = false;

  private onScroll(e: any): void {
    if (typeof window === 'undefined') {
      return;
    }

    const top = window.pageYOffset || e.target.scrollTop || 0;
    this.scrollTopButton = top > 100;
  }

  private created(): void {
    this.showCookiesInfoDialog = !localStorage.getItem(this.cookiesInfoAck);
  }

  private mounted(): void {
    this.resetAfkTimer();

    const resetLanguageDialogOverride = (lang: string) => {
      if (lang === LangSelectorService.defaultLang) {
        localStorage.removeItem(this.langDialogOverrideKey);
      }
    };

    LangSelectorService.changeLangListeners.push(resetLanguageDialogOverride);

    this.$vuetify.goTo('body', {
      duration: 600,
      easing: 'easeInOutCubic'
    });
  }

  private beforeMount(): void {
    this.enableScrollUpFab();
  }

  // Language info
  private langDialogTitle = 'Warning!';
  private langDialogMessage =
    'Support for english language is experimental! Some parts of the site might not be translated.';
  private langDialogYesButton = 'Continue';
  private langDialogNoButton = 'Change to PL';
  private langDialogOverrideKey = 'HIDE_LANG_DIALOG';
  private langDialogAck = false;

  get langDialog(): boolean {
    if (this.language !== LangSelectorService.defaultLang) {
      return !localStorage.getItem(this.langDialogOverrideKey) && !this.langDialogAck;
    }

    return false;
  }

  private onLangDialogYes(): void {
    localStorage.setItem(this.langDialogOverrideKey, 'true');
    this.langDialogAck = true;
  }

  private onLangDialogNo(): void {
    localStorage.removeItem(this.langDialogOverrideKey);
    LangSelectorService.lang = LangSelectorService.defaultLang;
  }
}
