
import { Component, Prop } from 'vue-property-decorator';
import { DictionariesService } from '@/modules/core/core-dynamic-dictionaries';
import PwrVue from '@/components/PwrVue';
import flagEn from '@/assets/pwr/icons/united_kingdom_flag.svg';
import flagDe from '@/assets/pwr/icons/germany_flag.svg';
import flagUk from '@/assets/pwr/icons/ukraine_flag.svg';
import flagPl from '@/assets/pwr/icons/poland_flag.svg';
import triangleDown from '@/assets/pwr/icons/triangle-down.svg';
import triangleUp from '@/assets/pwr/icons/triangle-up.svg';
import flagEmpty from '@/assets/pwr/icons/empty_flag.svg';
import PwrLangItem from '@/components/Pwr/PwrLangSelector/PwrLangItem.vue';
import PwrLangList from '@/components/Pwr/PwrLangSelector/PwrLangList.vue';

@Component({
  components: { PwrLangList, PwrLangItem }
})
export default class PwrLangSelector extends PwrVue {
  @Prop() langs!: string[];
  @Prop() expanded!: string | boolean;

  get triangleIcon() {
    return this.menuVisible ? triangleUp : triangleDown;
  }

  private menuVisible: boolean = false;

  private getFlag(lang: string) {
    switch (lang) {
      case 'en':
        return flagEn;

      case 'de':
        return flagDe;

      case 'uk':
        return flagUk;

      case 'pl':
        return flagPl;

      default:
        return flagEmpty;
    }
  }

  private updateLang() {
    DictionariesService.fetchDictionaries();
  }
}
