
import { Component, Prop, VModel } from 'vue-property-decorator';
import { TokenService } from '@/modules/core/core-auth';
import RouterLinkData from '@/components/Op/Layout/types';
import PwrVue from '@/components/PwrVue';
import PwrNavItem from '@/components/Pwr/PwrNavBar/PwrNavItem.vue';
import PwrNavBar from '@/components/Pwr/PwrNavBar/PwrNavBar.vue';
import PwrLangSelector from '@/components/Pwr/PwrLangSelector/PwrLangSelector.vue';
import OpAvatar from '@/components/Op/Layout/OpAvatar/OpAvatar.vue';

@Component({
  components: {
    OpAvatar,
    PwrNavItem,
    PwrNavBar,
    PwrLangSelector
  }
})
export default class OpNavBar extends PwrVue {
  @VModel() mobileMenu!: boolean;
  @Prop({ required: true }) navItems!: RouterLinkData[];

  private languageList = ['pl', 'en'];

  private closeMenu() {
    this.mobileMenu = false;
    this.$emit('close');
  }

  private isItemVisible(item: RouterLinkData): boolean {
    const isVisible = typeof item.visible === 'undefined' ? true : item.visible;
    const isAuthorised =
      typeof item.authorized === 'undefined'
        ? true
        : item.authorized && TokenService.isAccessTokenValid();

    return isVisible && isAuthorised;
  }
}
