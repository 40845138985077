
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { VueClassAndStyleBinder } from '@/types';
import PwrVue from '@/components/PwrVue';
import { PwrSnackbarIcon, PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import './PwrSnackbar.scss';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import frownIcon from '@/assets/pwr/icons/snackbars/frown.svg';
import infoIcon from '@/assets/pwr/icons/snackbars/info.svg';
import smileIcon from '@/assets/pwr/icons/snackbars/smile.svg';

@Component({
  components: { PwrBtn, PwrCardTitle, PwrCard }
})
export default class PwrSnackbar extends PwrVue {
  @VModel() vModelVisible!: boolean;
  @Prop() titleProp!: string;
  @Prop({ default: () => PwrSnackbarTypes.DEFAULT }) type!: PwrSnackbarTypes;
  @Prop({ default: () => PwrSnackbarIcon.NONE }) icon!: PwrSnackbarIcon;
  @Prop() top!: string | boolean;
  @Prop() center!: string | boolean;
  @Prop() bottom!: string | boolean;
  @Prop() spacing!: string | boolean;
  @Prop() absolute!: string | boolean;
  @Prop() darken!: string | boolean;
  @Prop({ default: () => 0 }) elevation!: number;
  @Prop() timeout!: number;

  private internalVisible = true;
  private debounceTimeout = debounce(this.timeoutDebounceTarget, this.timeout);

  private icons: { [key in PwrSnackbarIcon]?: any } = {
    [PwrSnackbarIcon.NONE]: undefined,
    [PwrSnackbarIcon.SMILE]: smileIcon,
    [PwrSnackbarIcon.FROWN]: frownIcon,
    [PwrSnackbarIcon.INFO]: infoIcon
  };

  get title(): string | undefined {
    let typeVal: string = this.type;

    if (typeVal === '') {
      typeVal = 'default';
    }

    return (
      this.titleProp || (this.$t(`components.pwr.snackbar.default.titles.${typeVal}`) as string)
    );
  }

  get computedRootClasses() {
    return [
      this.type !== PwrSnackbarTypes.DEFAULT ? this.type : '',
      this.isDesktop ? 'px-10 py-5' : 'pa-4',
      this.elevation > 0 ? `elevation-${this.elevation}` : ''
    ];
  }

  get computedMainRowClasses(): VueClassAndStyleBinder {
    return { 'px-16 py-4': this.isDesktop };
  }

  get computedWrapperClass(): VueClassAndStyleBinder {
    return {
      'pwr-snackbar-wrapper':
        this.isProp(this.center) || this.isProp(this.top) || this.isProp(this.absolute),
      'pwr-snackbar-spacing': this.isProp(this.spacing),
      'darken': this.isProp(this.darken),
      'center': this.isProp(this.center),
      'top': this.isProp(this.top),
      'pwr-snackbar-bottom': this.isProp(this.bottom)
    };
  }

  get computedIconColSize(): string | number {
    if (!this.isDesktop) {
      return 12;
    }

    return this.hasActions ? 'auto' : 1;
  }

  get computedIcon() {
    if (this.type !== PwrSnackbarTypes.DEFAULT && this.icon === PwrSnackbarIcon.NONE) {
      switch (this.type) {
        case PwrSnackbarTypes.SUCCESS:
          return this.icons[PwrSnackbarIcon.SMILE];
        case PwrSnackbarTypes.ERROR:
          return this.icons[PwrSnackbarIcon.FROWN];
        case PwrSnackbarTypes.INFO:
        case PwrSnackbarTypes.WARNING:
          return this.icons[PwrSnackbarIcon.INFO];
        default:
          return undefined;
      }
    }

    if (this.icon !== PwrSnackbarIcon.NONE) {
      return this.icons[this.icon];
    }

    return undefined;
  }

  get hasActions(): boolean {
    return !!this.$slots.actions;
  }

  private timeoutDebounceTarget() {
    this.visible = false;
  }

  @Watch('visible', { deep: true })
  private onVisibleChange() {
    if (this.visible && this.timeout) {
      this.debounceTimeout();
    }
  }

  get visible(): boolean {
    if (typeof this.vModelVisible !== 'undefined') {
      this.internalVisible = this.vModelVisible;
      return this.vModelVisible;
    }

    return this.internalVisible;
  }

  set visible(val: boolean) {
    this.internalVisible = val;
    this.vModelVisible = val;
  }

  private mounted(): void {
    if (this.timeout) {
      this.debounceTimeout();
    }
  }
}
