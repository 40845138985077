
import { Component, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import RouterLinkData from '@/components/Op/Layout/types';
import PwrVue from '@/components/PwrVue';
import PwrMenuBtn from '@/components/Pwr/Buttons/PwrMenuBtn/PwrMenuBtn.vue';

@Component({
  components: { PwrMenuBtn }
})
export default class PwrNavItem extends PwrVue {
  @Prop({ required: true }) routeData!: RouterLinkData;

  private async onClick(): Promise<void> {
    this.resetAfkTimer();
    this.$emit('click');
  }

  get targetRoute(): Route {
    return {
      name: this.routeData.name,
      params: this.routeData.params,
      query: this.routeData.query
    } as Route;
  }
}
