
import { Component, Prop } from 'vue-property-decorator';
import RouterLinkData from '@/components/Op/Layout/types';
import { RoutesNames as SiteRoutesNames } from '@/router/site/types';
import PwrVue from '@/components/PwrVue';
import pwrLogoEn from '@/assets/pwr/logo PWr kolor poziom ang  bez tla.png';
import pwrLogoPl from '@/assets/pwr/logo PWr kolor poziom  bez tla.png';
import pwrLogotype from '@/assets/pwr/logotyp/logotyp_pwr_kolor.png';
import bars from '@/assets/pwr/icons/bars.svg';
import times from '@/assets/pwr/icons/times.svg';
import PwrNavItem from '@/components/Pwr/PwrNavBar/PwrNavItem.vue';
import PwrNavBar from '@/components/Pwr/PwrNavBar/PwrNavBar.vue';
import PwrLangSelector from '@/components/Pwr/PwrLangSelector/PwrLangSelector.vue';
import OpNavBar from '@/components/Op/Layout/OpNavBar.vue';
import OpAvatar from '@/components/Op/Layout/OpAvatar/OpAvatar.vue';

@Component({
  components: {
    OpAvatar,
    OpNavBar,
    PwrNavItem,
    PwrNavBar,
    PwrLangSelector
  }
})
export default class OpNavigation extends PwrVue {
  @Prop({ required: true }) navItems!: RouterLinkData[];

  private languageList = ['pl', 'en'];
  private mobileMenu = false;

  get pwrLogo() {
    if (!this.isDesktop) {
      return pwrLogotype;
    }

    return this.language !== 'pl' ? pwrLogoEn : pwrLogoPl;
  }

  get mobileMenuIco() {
    return this.mobileMenu ? times : bars;
  }

  get pwrLogoHeight() {
    return this.isDesktop ? '85rem' : '55rem';
  }

  private toggleMobileMenu(): void {
    this.mobileMenu = !this.mobileMenu;
    this.$emit('mobileMenuToggled', this.mobileMenu);
  }

  private async goHome(): Promise<void> {
    await this.routesHelper.go(SiteRoutesNames.OFFERS_FOR_TEACHERS);
  }
}
