
import { Component, Prop } from 'vue-property-decorator';
import LangSelectorService from '@/modules/LangSelector/LangSelectorService';
import PwrVue from '@/components/PwrVue';
import PwrLangItem from '@/components/Pwr/PwrLangSelector/PwrLangItem.vue';

@Component({
  components: { PwrLangItem }
})
export default class PwrLangList extends PwrVue {
  @Prop({ required: true }) langs!: string[];
  @Prop({ required: true }) getFlag!: (language: string) => any;
  @Prop({ default: true }) currentLanguageFirst!: string | boolean;

  get languages() {
    return this.isProp(this.currentLanguageFirst)
      ? [this.language].concat(this.langs.filter((value) => value !== this.language))
      : this.langs;
  }

  private change(newLang: string): void {
    LangSelectorService.lang = newLang;
    this.$emit('change', newLang);
  }
}
