
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import triangleUp from '@/assets/pwr/icons/triangle-up.svg';

@Component
export default class PwrLangItem extends PwrVue {
  @Prop() lang!: string;
  @Prop() flag!: any;
  @Prop({ default: () => false }) triangle!: boolean;
  @Prop({ default: () => false }) triangleOnItem!: boolean;

  get triangleIcon() {
    return triangleUp;
  }

  get opacity() {
    return this.triangle ? 'opacity: 100' : 'opacity: 0';
  }

  get rotation() {
    return this.triangleOnItem ? 'transform: rotate(45)' : 'transform: rotate(0)';
  }

  private onClick(): void {
    this.resetAfkTimer();
    this.$emit('click', this.lang);
  }
}
